import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { DataProvider, jwtToken } from './context';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Spinner } from 'reactstrap';
import { colorsDefault } from './colors-default';
import { makeRequest } from './services';
import axios from 'axios';

function MainApp() {
  const [styleData, setStyleData] = useState(null);
  const [tokenData, setTokenData] = useState(undefined);

  // Get query parameters from the URL
  const urlParams = new URLSearchParams(window.location.search);
  const oneTimeToken = urlParams.get('token');
  const sourceUrl = window.location.href;
  const source = urlParams.get('source') || '';
  const sourceType = urlParams.get('sourceType') || '';
  const loadingColor = urlParams.get('loadingColor') || 'black';

  async function init() {
    makeRequest('post', 'appointment/page/auth/one-time', { oneTimeToken, source, sourceUrl, sourceType }).then(
      async ({ data: result, errorMessage }) => {
        if (errorMessage) {
          toast.error(errorMessage);
          return;
        }
        jwtToken.token = result.token;
        jwtToken.slots = result.slots;
        jwtToken.timeZone = result.timeZone;
        jwtToken.apptInfo = result.apptInfo;
        jwtToken.userInfo = result.userInfo;
        jwtToken.parentUrl = result.parentUrl;
        jwtToken.trustedForm = result.trustedForm;
        setTokenData({ ...result, oneTimeToken });
        let css;
        if (result?.styleData?.css?.url) {
          try {
            const response = await axios.get(result.styleData.css.url);
            css = response?.data;
          } catch (ex) {
            console.error(ex.response?.data?.message || ex.message);
          }
        }
        css = css || colorsDefault;
        const styleElement = document.createElement('style');
        styleElement.innerHTML = css;
        styleElement.onload = () => {
          setStyleData(true);
        };
        document.head.appendChild(styleElement);
      }
    );
  }

  useEffect(() => {
    init();
  }, []);

  if (!styleData) {
    return (
      <div style={{ textAlign: 'center' }}>
        <h4 style={{ marginTop: '16px', color: loadingColor }}>Just a moment...</h4>
        <Spinner style={{ width: '2rem', height: '2rem', color: loadingColor }}>&nbsp;</Spinner>
      </div>
    );
  }

  return (
    <React.StrictMode>
      <DataProvider tokenData={tokenData}>
        <App />
        <ToastContainer />
      </DataProvider>
    </React.StrictMode>
  );
}

ReactDOM.render(<MainApp />, document.getElementById('root'));
